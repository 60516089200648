import * as React from "react";
import Container from "../components/container";
import Layout from "../components/layout";
// import { StaticImage } from "gatsby-plugin-image";
import {
  mainContent,
  // description,
  row,
  //col6,
  col12,
  marginTopSubstitute,
  introSection,
} from "./subpage.module.scss";

const InteroperabilitaetsplattformPage = () => {
  return (
    <Layout pageTitle="Interoperabilitätsplattform" neutralPage={true}>
      <main className={mainContent}>
        <section className={introSection}>
          <Container oneColumn={true}>
            <div className={row}>
              <div className={col12}>
                {/* <p className={description}></p> */}
                <h1>Interoperabilitäts&shy;plattform</h1>
                <p className="text-medium">
                  Ungehinderter Datenaustausch und übergreifende Steuerung von
                  Therapieprozessen ermöglichen eine effiziente
                  interdisziplinäre Zusammenarbeit zwischen Abteilungen,
                  Funktionsbereichen und Standorten.
                </p>
                <p>
                  Mit Standards wie IHE, HL7, FHIR und DICOM vermeidet die
                  health-engine ein proprietäres Datenmanagement. Das umfassende
                  Prozessmanagement schliesst die Einbindung von Haus- und
                  Fachärzten sowie anderen Leistungserbringern ein. Und das
                  Berechtigungsmanagement für Personen, Rollen und Organigramme
                  sorgt für die Einhaltung gesetzlicher Vorgaben. Ein
                  lückenloser Audit-Trail gewährleistet die Nachvollziehbarkeit
                  aller Prozesse und deren Zuordnung zu den einzelnen
                  Beteiligten.
                </p>
              </div>
            </div>

            <div className={row}>
              <div className={col12}>
                <h3>Schnittstellen</h3>
                <ul>
                  <li>Über 100 Schnittstellen</li>
                  <li>Alle Standards</li>
                  <li>Proprietäre Formate von diversen Herstellern</li>
                  <li>Einzelne Spring Boot Komponenten</li>
                </ul>

                <div className={marginTopSubstitute}>
                  <h3>Schnittstellenüberwachung</h3>
                  <ul>
                    <li>Präventive Überwachung der Schnittstellen</li>
                    <li>
                      Email Alert falls eine Schnittstelle nicht richtig
                      reagiert
                    </li>
                    <li>
                      Überwachungszeitraum definierbar (Wochentag,
                      Uhrzeitbereich) Prüfintervall definierbar
                    </li>
                  </ul>
                </div>
              </div>

              <div className={col12}>
                <h3>HL7 v2, v3, Inbound, Outbound</h3>
                <ul>
                  <li> HL7 ADT Pat/Fall</li>
                  <li>HL7 MDM Dokument</li>
                  <li>HL7 ORU Befund, Labor</li>
                  <li>HL7 ORM Anforderung</li>
                  <li>HL7 BAR Leitungsabrechnung</li>
                  <li>HL7 ORR Statusmeldung</li>
                  <li>HL7 SRM Terminanfrage</li>
                  <li>HL7 SIU Terminvereinbarung</li>
                  <li>HL7 OMG Klinische Aufträge</li>
                  <li>HL7 ACK Bestätigung</li>
                  <li>HL7 GT1 Geschäftspartner</li>
                  <li>HL7 MFN Mitarbeiter, ...</li>
                  <li>HL7 DFT Leistungsdaten zur Abrechnung</li>
                </ul>
              </div>

              <div className={col12}>
                <h3>FHIR</h3>
                <ul>
                  <li>Bundle: http://hl7.org/fhir/R4/bundle.html</li>
                  <li>
                    DocumentManifest: http://hl7.org/fhir/R4/
                    documentmanifest.html
                  </li>
                  <li>
                    DocumentReference: http://hl7.org/fhir/
                    R4/documentreference.html
                  </li>
                  <li>Encounter: http://hl7.org/fhir/R4/encounter.html</li>
                  <li>Location: http://hl7.org/fhir/R4/location.html</li>
                  <li>
                    Organization: http://hl7.org/fhir/R4/organization.html
                  </li>
                  <li>Patient: http://hl7.org/fhir/R4/patient.html</li>
                  <li>
                    Practitioner: http://hl7.org/fhir/R4/practitioner.html
                  </li>
                  <li>
                    Observation: https://www.hl7.org/fhir/observation.html
                  </li>
                </ul>
              </div>

              <div className={col12}>
                <h3>EPD, IHE</h3>
                <ul>
                  <li> IHE - CDA</li>
                  <li> IHE - WADO</li>
                  <li> IHE - XDS</li>
                  <li> IHE - XDS-I</li>
                  <li> IHE - PIX</li>
                  <li> IHE - PDQ</li>
                  <li> IHE - XUA</li>
                  <li> IHE - ATNA</li>
                  <li> IHE - HPD, Source, Consumer</li>
                  <li> IHE - MPI, Source, Consumer</li>
                  <li> IHE - SVS, Consumer</li>
                </ul>
              </div>

              <div className={col12}>
                <h3>Unterstützte MHDS Profile</h3>
                <ol>
                  <li>
                    <span className="text-blue">
                      ITI-65 (Provide Document Bundle - MHD):
                    </span>{" "}
                    Diese Transaktion wurde erst beim 2023 IHE Europe
                    Connectathon in Rennes bestätigt. Sie ermöglicht das
                    Bereitstellen von Dokumentenbündeln im MHD-Kontext.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-66 (Retrieve Document Bundle - MHDS):
                    </span>{" "}
                    Ebenfalls beim 2023 IHE Europe Connectathon in Rennes
                    bestätigt, dient diese Transaktion dem Abrufen von
                    Dokumentenbündeln im MHDS-Kontext.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-41 (Provide and Register Document Set-b):
                    </span>{" "}
                    Diese Transaktion wird genutzt, um Dokumente und Metadaten
                    an ein Dokumentenregister zu übermitteln. Sie ist ein
                    Schlüsselelement im XDS.b-Profil und kann für die
                    Registrierung von Dokumenten im MHD-Kontext wichtig sein.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-42 (Register Document Set-b):
                    </span>{" "}
                    Hierbei geht es um die reine Registrierung von Metadaten
                    ohne das eigentliche Dokument. Nützlich für Szenarien, in
                    denen das Dokument anderswo gespeichert, aber Metadaten im
                    Register verfügbar sein sollen.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-43 (Retrieve Document Set):
                    </span>{" "}
                    Wird verwendet, um Dokumente aus einem Dokumentenrepository
                    abzurufen. Diese Transaktion ist entscheidend, um auf die
                    über ITI-41 bereitgestellten Dokumente zuzugreifen.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-62 (Delete Document Set):
                    </span>{" "}
                    Zum Löschen von Dokumenten und ihren Metadaten aus dem
                    Register genutzt, wichtig für das Lebenszyklusmanagement von
                    Dokumenten.
                  </li>
                  <h3 style={{ marginTop: 30 }}>In Prüfung:</h3>
                  <li>
                    <span className="text-blue">
                      ITI-61 (Register On-Demand Document Entry):
                    </span>{" "}
                    Ermöglicht die Erstellung von „on-demand“ Dokumenten, die
                    bei Bedarf generiert werden.
                  </li>
                  <li>
                    <span className="text-blue">
                      ITI-92 (Restricted Update Document Set):
                    </span>{" "}
                    Ermöglicht das Abrufen von Manifesten für mobile Dokumente,
                    relevant für mobile Anwendungen im Gesundheitswesen.
                  </li>
                </ol>
              </div>

              <div className={col12}>
                <h3>Technologien</h3>
                <ul>
                  <li>File</li>
                  <li>XML</li>
                  <li>CSV</li>
                  <li>Email</li>
                  <li>IMAP</li>
                  <li>Web Service (REST, SOAP,...)</li>
                  <li>http/s</li>
                  <li>FTP/S</li>
                  <li>NFS</li>
                  <li>CIFS</li>
                  <li>Windows Druckertreiber</li>
                  <li>SAPArchiveLink</li>
                  <li>SAP-Rechnung</li>
                </ul>
              </div>

              <div className={col12}>
                <h3>Security</h3>
                <ul>
                  <li>Active Directory</li>
                  <li>Token (z.B. JWT)</li>
                  <li>SSO</li>
                  <li>RADIUS</li>
                  <li>mTan</li>
                  <li>iTan</li>
                  <li>WebAuthn (FIDO2)</li>
                  <li>OIDC (OAuth 2.0)</li>
                  <li>Two Factor Autorisierungen (diverse)</li>
                  <li>Signatur Service</li>
                  <li>PDF/A</li>
                </ul>
              </div>

              <div className={col12}>
                <h3>Weitere Schnittstellen</h3>
                <ul>
                  <li> PDF Konvertierung, OCR</li>
                  <li> DICOM</li>
                  <li> REST</li>
                  <li> XML</li>
                  <li> XML mit embedded Files</li>
                  <li> CSV</li>
                  <li> Files</li>
                  <li> SAP BAPI</li>
                  <li> HCM</li>
                  <li> HIN</li>
                  <li> docbox</li>
                  <li> SAP-Geschäftspartner</li>
                  <li> Openmedical</li>
                  <li> OPAN Spitex API</li>
                  <li> Medical Connector</li>
                  <li> Evita</li>
                  <li> OCR</li>
                </ul>
              </div>
            </div>
          </Container>
        </section>
      </main>
    </Layout>
  );
};

export default InteroperabilitaetsplattformPage;
